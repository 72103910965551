import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { instance } from 'utils/axios'

const initialState = {
  loading: false,
  ads: null,
  count: 0,
  error: null
}

export const adsListAction = createAsyncThunk(
  'ads/adsList',
  async (params, { rejectWithValue }) => {
    try {
      const { location, page, change, limit = 15 } = params

      let link =
        location.value !== 'All'
          ? `/api/postings?location=${location.label}&page=${page}&limit=${limit}`
          : `/api/postings?page=${page}&limit=${limit}`
      const { data } = await instance.get(link)

      return { data, change }
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const adsListSlice = createSlice({
  name: 'adsList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(adsListAction.pending, (state) => {
        state.loading = true
      })
      .addCase(adsListAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.count = payload.data.count
        if (payload.change) {
          state.ads = payload.data.ads
        } else {
          state.ads = [...state.ads, ...payload.data.ads]
        }
      })
      .addCase(adsListAction.rejected, (state, action) => {
        state.loading = false
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = action.error.message
        }
      })
  }
})

export default adsListSlice.reducer
