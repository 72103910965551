import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { Container, Flex } from '@chakra-ui/layout'
import { useMediaQuery } from '@chakra-ui/media-query'

import Loader from './components/elements/Loader'
import PrivateRoute, {
  AdminRoute,
  PersonalAccountRoute
} from './components/PrivateRoute'
import NotFound from './screens/NotFoundScreen'

const Header = React.lazy(() => import('./components/headerFooter/Header'))
const Footer = React.lazy(() => import('./components/headerFooter/Footer'))

const AllPostingsScreen = React.lazy(() =>
  import('./screens/AllPostingsScreen')
)
const AdScreen = React.lazy(() => import('./screens/AdScreen'))
const HomeScreen = React.lazy(() => import('./screens/HomeScreen'))
const SearchScreen = React.lazy(() => import('./screens/SearchScreen'))
const SellerProfile = React.lazy(() => import('./screens/SellerProfile'))
const CategoryScreen = React.lazy(() => import('./screens/CategoryScreen'))

/*const BusinessAccountScreen = React.lazy(() =>
  import('./screens/businessAccount/BAScreen')
)*/

const EditAdScreen = React.lazy(() =>
  import('./screens/adActions/EditAdScreen')
)
const Sell = React.lazy(() => import('./screens/adActions/Sell'))

const MyAds = React.lazy(() => import('./screens/auth/MyAds'))
const Packages = React.lazy(() => import('./screens/auth/Packages'))
const EditProfile = React.lazy(() => import('./screens/auth/EditProfile'))
const CoinZoneScreen = React.lazy(() => import('./screens/auth/CoinZoneScreen'))

const SalesListScreen = React.lazy(() =>
  import('./screens/admin/SalesListScreen')
)
const AccountEditScreen = React.lazy(() =>
  import('./screens/admin/AccountEditScreen')
)
const AdsApprovalScreen = React.lazy(() =>
  import('./screens/admin/AdsApprovalScreen')
)
const AllAccountsScreen = React.lazy(() =>
  import('./screens/admin/AllAccountsScreen')
)
const AdListScreen = React.lazy(() => import('./screens/admin/AdListScreen'))

function App() {
  const [isSm] = useMediaQuery('(max-width: 1023px)')
  return (
    <Router>
      <Flex id='wrapper' direction='column'>
        <Suspense fallback={<Loader type='small' />}>
          <Header />
        </Suspense>
        <main>
          <Container maxW='container.xl' px={isSm ? 1 : 4}>
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path='/' element={<HomeScreen />} />
                {/*<Route
                  path='/business-account'
                  element={<BusinessAccountScreen/>}
                />*/}
                <Route
                  path='/all-postings/:location'
                  element={<AllPostingsScreen />}
                />
                <Route
                  path='/postings/:location/:category'
                  element={<CategoryScreen />}
                />
                <Route path='/search' element={<SearchScreen />} />
                <Route path='/seller/:id' element={<SellerProfile />} />
                <Route path='/posting/:title/:id' element={<AdScreen />} />

                <Route
                  path='/edit-posting/:id'
                  element={
                    <PrivateRoute>
                      <EditAdScreen />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/sell'
                  element={
                    <PrivateRoute>
                      <Sell />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/my-postings'
                  element={
                    <PrivateRoute>
                      <MyAds />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/coin-zone'
                  element={
                    <PrivateRoute>
                      <CoinZoneScreen />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/edit-profile'
                  element={
                    <PrivateRoute>
                      <EditProfile />
                    </PrivateRoute>
                  }
                />

                <Route
                  path='/packages'
                  element={
                    <PersonalAccountRoute>
                      <Packages />
                    </PersonalAccountRoute>
                  }
                />

                <Route
                  path='/admin/accounts'
                  element={
                    <AdminRoute>
                      <AllAccountsScreen />
                    </AdminRoute>
                  }
                />
                <Route
                  path='/admin/seller/:id/edit'
                  element={
                    <AdminRoute>
                      <AccountEditScreen />
                    </AdminRoute>
                  }
                />
                <Route
                  path='/admin/postings-for-approval'
                  element={
                    <AdminRoute>
                      <AdsApprovalScreen />
                    </AdminRoute>
                  }
                />
                <Route
                  path='/admin/sales'
                  element={
                    <AdminRoute>
                      <SalesListScreen />
                    </AdminRoute>
                  }
                />
                <Route
                  path='/admin/postings'
                  element={
                    <AdminRoute>
                      <AdListScreen />
                    </AdminRoute>
                  }
                />
                <Route element={<NotFound />} />
              </Routes>
            </Suspense>
          </Container>
        </main>
        <Suspense fallback={<Loader type='small' />}>
          <Footer />
        </Suspense>
      </Flex>
    </Router>
  )
}

export default App
