import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { instance } from 'utils/axios'
import { sellerListReset } from '../sellerState/sellerList'
import { adAddReset } from '../adsState/adAdd'
import { adUpdateReset } from '../adsState/adUpdate'
import { adDeleteReset } from '../adsState/adDelete'
import { sellerSelfUpdateReset } from './sellerSelfUpdate'

const initialState = {
  loading: false,
  sellerInfo: localStorage.getItem('sellxpressSellerInfo')
    ? JSON.parse(localStorage.getItem('sellxpressSellerInfo'))
    : null,
  error: null
}

export const sellerLoginAction = createAsyncThunk(
  'sellerAuth/sellerLogin',
  async ({ phone }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      const { data } = await instance.post(
        '/api/sellers/login',
        { phone },
        config
      )

      localStorage.setItem('sellxpressSellerInfo', JSON.stringify(data))

      return data
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const sellerLogoutAction = createAsyncThunk(
  'sellerAuth/sellerLogout',
  async (args, { dispatch }) => {
    localStorage.removeItem('sellxpressSellerInfo')
    dispatch(sellerListReset())
    dispatch(sellerSelfUpdateReset())
    dispatch(adAddReset())
    dispatch(adUpdateReset())
    dispatch(adDeleteReset())
  }
)

export const sellerLoginSlice = createSlice({
  name: 'sellerLogin',
  initialState,
  reducers: {
    registeredSellerLogin: (state, { payload }) => {
      state.loading = false
      state.sellerInfo = payload
    },
    sellerAuthReset: (state) => {
      state.loading = false
      state.sellerInfo = null
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(sellerLoginAction.pending, (state) => {
        state.loading = true
      })
      .addCase(sellerLoginAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.sellerInfo = payload
      })
      .addCase(sellerLoginAction.rejected, (state, action) => {
        state.loading = false
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = action.error.message
        }
      })
      .addCase(sellerLogoutAction.fulfilled, (state) => {
        state.loading = false
        state.sellerInfo = null
        state.error = null
      })
  }
})

export const { registeredSellerLogin, sellerAuthReset } =
  sellerLoginSlice.actions

export default sellerLoginSlice.reducer
