import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ChakraProvider } from '@chakra-ui/react'

import App from './App'
import theme from './chakraTheme'
import rtkStore from './state/rtkStore'
import { getChoices } from './state/miscState/choices'

import 'react-image-gallery/styles/css/image-gallery.css'
import './custom.css'
import './firebaseui-styling.global.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

rtkStore.dispatch(getChoices())

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <Provider store={rtkStore}>
      <App />
    </Provider>
  </ChakraProvider>,
  document.getElementById('root')
)

serviceWorkerRegistration.register()
