import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { instance } from 'utils/axios'

const initialState = {
  loading: false,
  sales: null,
  error: null
}

export const salesListAction = createAsyncThunk(
  'adminState/salesList',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        sellerLogin: { sellerInfo }
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${sellerInfo.token}`,
          'Content-Type': 'application/json'
        }
      }

      const { data } = await instance.get('/api/sales/all-sales', config)

      return data
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const salesListSlice = createSlice({
  name: 'salesList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(salesListAction.pending, (state) => {
        state.loading = true
      })
      .addCase(salesListAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.sales = payload
      })
      .addCase(salesListAction.rejected, (state, action) => {
        state.loading = false
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = action.error.message
        }
      })
  }
})

export default salesListSlice.reducer
