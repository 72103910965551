import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { instance } from 'utils/axios'

const initialState = {
  loading: false,
  ads: null,
  count: 0,
  error: null
}

export const adsByKeywordAction = createAsyncThunk(
  'ads/adsByKeyword',
  async ({ location, keyword, query, page, change }, { rejectWithValue }) => {
    try {
      let link =
        location.value !== 'All'
          ? `/api/postings/search?page=${page}&location=${location.label}&keyword=${keyword}`
          : `/api/postings/search?page=${page}&keyword=${keyword}`

      const { minPrice, maxPrice } = query

      if (minPrice && maxPrice) {
        link += `&minPrice=${minPrice}&maxPrice=${maxPrice}`
      } else if (minPrice) {
        link += `&minPrice=${minPrice}`
      } else if (maxPrice) {
        link += `&maxPrice=${maxPrice}`
      }

      const { data } = await instance.get(link)

      return { data, change }
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const adsByKeywordSlice = createSlice({
  name: 'adsByKeyword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(adsByKeywordAction.pending, (state) => {
        state.loading = true
      })
      .addCase(adsByKeywordAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.count = payload.data.count
        if (payload.change) {
          state.ads = payload.data.ads
        } else {
          state.ads = [...state.ads, ...payload.data.ads]
        }
      })
      .addCase(adsByKeywordAction.rejected, (state, action) => {
        state.loading = false
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = action.error.message
        }
      })
  }
})

export default adsByKeywordSlice.reducer
