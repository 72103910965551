import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

const PrivateRoute = ({ children }) => {
  const { sellerInfo } = useSelector((state) => state.sellerLogin)

  return sellerInfo ? children : <Navigate replace to='/' />
}

export const AdminRoute = ({ children }) => {
  const { sellerInfo } = useSelector((state) => state.sellerLogin)
  return !sellerInfo || !sellerInfo.isAdmin ? (
    <Navigate replace to='/' />
  ) : (
    children
  )
}

export const PersonalAccountRoute = ({ children }) => {
  const { sellerInfo } = useSelector((state) => state.sellerLogin)

  return !sellerInfo || sellerInfo.accountType === 'BusinessAccount' ? (
    <Navigate replace to='/' />
  ) : (
    children
  )
}

export default PrivateRoute
