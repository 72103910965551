import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { instance } from 'utils/axios'
import { sellerDetailsAction } from './sellerDetails'

const initialState = {
  loading: false,
  success: false,
  error: null
}

export const sellerUpdateAction = createAsyncThunk(
  'seller/sellerUpdate',
  async (seller, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        sellerLogin: { sellerInfo }
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sellerInfo.token}`
        }
      }

      await instance.put(`/api/sellers/${seller._id}`, seller, config)

      dispatch(sellerDetailsAction(seller._id))

      return true
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const sellerUpdateSlice = createSlice({
  name: 'sellerUpdate',
  initialState,
  reducers: {
    sellerUpdateReset: (state) => {
      state.loading = false
      state.success = false
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(sellerUpdateAction.pending, (state) => {
        state.loading = true
      })
      .addCase(sellerUpdateAction.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
      })
      .addCase(sellerUpdateAction.rejected, (state, action) => {
        state.loading = false
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = action.error.message
        }
      })
  }
})

export const { sellerUpdateReset } = sellerUpdateSlice.actions

export default sellerUpdateSlice.reducer
