import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { instance } from 'utils/axios'

const initialState = {
  loading: false,
  success: false,
  error: null
}

export const adViewsIncreaseAction = createAsyncThunk(
  'ads/adViewsIncrease',
  async (id, { rejectWithValue }) => {
    try {
      await instance.get(`/api/postings/view-plus/${id}`)
      return true
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const adViewsIncreaseSlice = createSlice({
  name: 'adViewsIncrease',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(adViewsIncreaseAction.pending, (state) => {
        state.loading = true
      })
      .addCase(adViewsIncreaseAction.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
      })
      .addCase(adViewsIncreaseAction.rejected, (state, action) => {
        state.loading = false
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = action.error.message
        }
      })
  }
})

export default adViewsIncreaseSlice.reducer
