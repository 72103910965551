import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { instance } from 'utils/axios'

const initialState = {
  loading: false,
  success: false,
  error: null
}

export const approveAdAction = createAsyncThunk(
  'admin/approveAd',
  async (id, { rejectWithValue, getState }) => {
    try {
      const {
        sellerLogin: { sellerInfo }
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${sellerInfo.token}`
        }
      }

      await instance.put(`/api/postings/approval/${id}`, null, config)

      return true
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const approveAdSlice = createSlice({
  name: 'approveAd',
  initialState,
  reducers: {
    approveAdReset: (state) => {
      state.loading = false
      state.success = false
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(approveAdAction.pending, (state) => {
        state.loading = true
      })
      .addCase(approveAdAction.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
      })
      .addCase(approveAdAction.rejected, (state, action) => {
        state.loading = false
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = action.error.message
        }
      })
  }
})

export const { approveAdReset } = approveAdSlice.actions

export default approveAdSlice.reducer
