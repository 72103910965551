import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { instance } from 'utils/axios'

const initialState = {
  loading: false,
  ads: null,
  count: 0,
  error: null
}

export const adApprovalListAction = createAsyncThunk(
  'admin/adApprovalList',
  async ({ page, toChange }, { rejectWithValue, getState }) => {
    try {
      const {
        sellerLogin: { sellerInfo }
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${sellerInfo.token}`
        }
      }

      const { data } = await instance.get(
        `/api/postings/approval?page=${page}`,
        config
      )
      return { data, toChange }
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const adApprovalListSlice = createSlice({
  name: 'adApprovalList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(adApprovalListAction.pending, (state) => {
        state.loading = true
      })
      .addCase(adApprovalListAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.count = payload.data.count
        if (payload.toChange) {
          state.ads = payload.data.ads
        } else {
          state.ads = [...state.ads, ...payload.data.ads]
        }
      })
      .addCase(adApprovalListAction.rejected, (state, action) => {
        state.loading = false
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = action.error.message
        }
      })
  }
})

export default adApprovalListSlice.reducer
