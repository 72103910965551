import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { instance } from 'utils/axios'

const initialState = {
  loading: false,
  sale: null,
  error: null
}

export const saleDetailsIdAction = createAsyncThunk(
  'saleState/saleDetailsId',
  async (id, { rejectWithValue, getState }) => {
    try {
      const {
        sellerLogin: { sellerInfo }
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${sellerInfo.token}`,
          'Content-Type': 'application/json'
        }
      }

      const { data } = await instance.get(`/api/sales/${id}`, config)

      return data
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const saleDetailsIdSlice = createSlice({
  name: 'saleDetailsId',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saleDetailsIdAction.pending, (state) => {
        state.loading = true
      })
      .addCase(saleDetailsIdAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.sale = payload
      })
      .addCase(saleDetailsIdAction.rejected, (state, action) => {
        state.loading = false
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = action.error.message
        }
      })
  }
})

export default saleDetailsIdSlice.reducer
