import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { instance } from 'utils/axios'

const initialState = {
  loading: false,
  ads: null,
  count: 0,
  error: null
}

export const adsByCategoryAction = createAsyncThunk(
  'ads/adsByCategory',
  async (details, { rejectWithValue }) => {
    try {
      const {
        category,
        location,
        subCategory,
        for: _for,
        minPrice,
        maxPrice,
        fuels,
        year,
        brands,
        page,
        change,
        keyword,
        limit
      } = details

      let link = `/api/postings?page=${page}&category=${category}`
      if (limit) {
        link += `&limit=${limit}`
      }
      if (location.value !== 'All') {
        link += `&location=${location.label}`
      }
      if (keyword) {
        link += `&keyword=${keyword}`
      }
      if (subCategory) {
        link += `&subCategory=${subCategory}`
      }
      if (_for) {
        let forarr = _for.split(',')
        forarr.map((f) => {
          return (link += `&for=${f}`)
        })
      }
      if (minPrice && maxPrice) {
        link += `&minPrice=${minPrice}&maxPrice=${maxPrice}`
      } else if (minPrice) {
        link += `&minPrice=${minPrice}`
      } else if (maxPrice) {
        link += `&maxPrice=${maxPrice}`
      }

      if (year && year !== 0) {
        link += `&year=${year}`
      }

      if (fuels && fuels !== '') {
        let farr = fuels.split(',')
        farr.map((fuel) => {
          return (link += `&fuelType=${fuel}`)
        })
      }

      if (brands && brands !== '') {
        let barr = brands.split(',')
        barr.map((brand) => {
          return (link += `&brand=${brand}`)
        })
      }

      const { data } = await instance.get(link)

      return { data, change }
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const adsByCategorySlice = createSlice({
  name: 'adsByCategory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(adsByCategoryAction.pending, (state) => {
        state.loading = true
      })
      .addCase(adsByCategoryAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.count = payload.data.count
        if (payload.change) {
          state.ads = payload.data.ads
        } else {
          state.ads = [...state.ads, ...payload.data.ads]
        }
      })
      .addCase(adsByCategoryAction.rejected, (state, action) => {
        state.loading = false
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = action.error.message
        }
      })
  }
})

export default adsByCategorySlice.reducer
