import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { instance } from 'utils/axios'

const initialState = {
  loading: false,
  success: false,
  error: null
}

export const businessAccountRequestAction = createAsyncThunk(
  'businessAccount/businessAccountRequest',
  async (props, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      const { data } = await instance.post(
        '/api/sellers/business-account',
        props,
        config
      )

      return data
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const businessAccountRequestSlice = createSlice({
  name: 'businessAccountRequest',
  initialState,
  reducers: {
    businessAccountRequestReset: (state) => {
      state.loading = false
      state.success = false
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(businessAccountRequestAction.pending, (state) => {
        state.loading = true
      })
      .addCase(businessAccountRequestAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload.success
      })
      .addCase(businessAccountRequestAction.rejected, (state, action) => {
        state.loading = false
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = action.error.message
        }
      })
  }
})

export const { businessAccountRequestReset } =
  businessAccountRequestSlice.actions

export default businessAccountRequestSlice.reducer
