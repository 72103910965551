import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { instance } from 'utils/axios'

const initialState = {
  loading: false,
  seller: null,
  error: null
}

export const sellerProfileAction = createAsyncThunk(
  'seller/sellerProfile',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await instance.get(`/api/sellers/${id}`)
      return { data }
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const sellerProfileSlice = createSlice({
  name: 'sellerProfile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sellerProfileAction.pending, (state) => {
        state.loading = true
      })
      .addCase(sellerProfileAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.seller = payload.data
      })
      .addCase(sellerProfileAction.rejected, (state, action) => {
        state.loading = false
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = action.error.message
        }
      })
  }
})

export default sellerProfileSlice.reducer
