import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { instance } from 'utils/axios'

const initialState = {
  loading: false,
  success: false,
  error: null
}

export const approveAccountAction = createAsyncThunk(
  'admin/approveAccount',
  async (id, { rejectWithValue, getState }) => {
    try {
      const {
        sellerLogin: { sellerInfo }
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${sellerInfo.token}`
        }
      }

      const { data } = await instance.put(
        `/api/sellers/business-account/${id}`,
        null,
        config
      )

      return data.success
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const approveAccountSlice = createSlice({
  name: 'approveAccount',
  initialState,
  reducers: {
    approveAccountReset: (state) => {
      state.loading = false
      state.success = false
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(approveAccountAction.pending, (state) => {
        state.loading = true
      })
      .addCase(approveAccountAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
      })
      .addCase(approveAccountAction.rejected, (state, action) => {
        state.loading = false
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = action.error.message
        }
      })
  }
})

export const { approveAccountReset } = approveAccountSlice.actions

export default approveAccountSlice.reducer
