import { configureStore } from '@reduxjs/toolkit'

import choicesReducer from './miscState/choices'
import searchLocationsReducer from './miscState/searchLocations'
import setLocationFilterReducer from './miscState/setLocationFilter'

import adAddReducer from './adsState/adAdd'
import adsListReducer from './adsState/adsList'
import adUpdateReducer from './adsState/adUpdate'
import adDeleteReducer from './adsState/adDelete'
import adDetailsReducer from './adsState/adDetails'
import adsByKeywordReducer from './adsState/adsByKeyword'
import adsByCategoryReducer from './adsState/adsByCategory'
import adViewsIncreaseReducer from './adsState/adViewsIncrease'

import salesListReducer from './adminState/salesList'
import approveAdReducer from './adminState/approveAd'
import adApprovalListReducer from './adminState/adApprovalList'
import approveAccountReducer from './adminState/approveAccount'

import sellerLoginReducer from './sellerAuthState/sellerAuth'
import sellerAdsListReducer from './sellerAuthState/sellerAdsList'
import sellerRegisterReducer from './sellerAuthState/sellerRegister'
import sellerSelfUpdateReducer from './sellerAuthState/sellerSelfUpdate'
import sellerSelfProfileReducer from './sellerAuthState/sellerSelfProfile'

import sellerAdsReducer from './sellerState/sellerAds'
import sellerListReducer from './sellerState/sellerList'
import sellerDeleteReducer from './sellerState/sellerDelete'
import sellerUpdateReducer from './sellerState/sellerUpdate'
import sellerDetailsReducer from './sellerState/sellerDetails'
import sellerProfileReducer from './sellerState/sellerProfile'

import businessAccountRequestReducer from './businessAccountState/businessAccountRequest'

import addSaleReducer from './saleState/addSale'
import saleDetailsReducer from './saleState/saleDetails'

const rtkStore = configureStore({
  reducer: {
    choices: choicesReducer,
    searchLocations: searchLocationsReducer,
    setLocationFilter: setLocationFilterReducer,
    adsList: adsListReducer,
    adAdd: adAddReducer,
    adUpdate: adUpdateReducer,
    adDelete: adDeleteReducer,
    adDetails: adDetailsReducer,
    adViewsIncrease: adViewsIncreaseReducer,
    adsByCategory: adsByCategoryReducer,
    adsByKeyword: adsByKeywordReducer,
    approveAd: approveAdReducer,
    adApprovalList: adApprovalListReducer,
    approveAccount: approveAccountReducer,
    sellerLogin: sellerLoginReducer,
    sellerRegister: sellerRegisterReducer,
    sellerAdsList: sellerAdsListReducer,
    sellerSelfUpdate: sellerSelfUpdateReducer,
    sellerSelfProfile: sellerSelfProfileReducer,
    sellerAds: sellerAdsReducer,
    sellerProfile: sellerProfileReducer,
    sellerList: sellerListReducer,
    sellerDetails: sellerDetailsReducer,
    sellerDelete: sellerDeleteReducer,
    sellerUpdate: sellerUpdateReducer,
    businessAccountRequest: businessAccountRequestReducer,
    addSale: addSaleReducer,
    saleDetails: saleDetailsReducer,
    salesList: salesListReducer
  }
})

export default rtkStore
