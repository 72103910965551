import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { instance } from 'utils/axios'

const initialState = {
  loading: false,
  sellerAds: null,
  count: 0,
  error: null
}

export const sellerAdsAction = createAsyncThunk(
  'seller/sellerAds',
  async ({ id, page, toChange }, { rejectWithValue }) => {
    try {
      const { data } = await instance.get(
        `/api/postings?page=${page}&sellerId=${id}`
      )
      return { data, toChange }
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const sellerAdsSlice = createSlice({
  name: 'sellerAds',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sellerAdsAction.pending, (state) => {
        state.loading = true
      })
      .addCase(sellerAdsAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.count = payload.data.count
        if (payload.toChange) {
          state.sellerAds = payload.data.ads
        } else {
          state.sellerAds = [...state.sellerAds, ...payload.data.ads]
        }
      })
      .addCase(sellerAdsAction.rejected, (state, action) => {
        state.loading = false
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = action.error.message
        }
      })
  }
})

export default sellerAdsSlice.reducer
