import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Text, Heading } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'

const NotFound = () => {
  return (
    <Box
      color='white'
      textAlign='center'
      borderRadius='xl'
      className='bg-gradient-danger'
    >
      <Box m={14} p={14}>
        <Heading as='h1' fontSize='8xl'>
          404
        </Heading>
        <Text fontSize='2xl'>The page you were looking for was not found!</Text>
        <Button as={RouterLink} to='/' size='lg' mt={4}>
          Goto Homepage
        </Button>
      </Box>
    </Box>
  )
}

export default NotFound
