import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  colors: {
    blue: {
      50: '#E5F2FF',
      100: '#B8DAFF',
      200: '#8AC2FF',
      300: '#5CABFF',
      400: '#2E93FF',
      500: '#007BFF',
      600: '#0062CC',
      700: '#004A99',
      800: '#003166',
      900: '#001933'
    },
    green: {
      50: '#EAFAEE',
      100: '#C5F1CF',
      200: '#A0E8B1',
      300: '#7BDF92',
      400: '#56D774',
      500: '#31CE55',
      600: '#27A544',
      700: '#1E7B33',
      800: '#145222',
      900: '#0A2911'
    }
  },
  components: {
    Button: {
      baseStyle: {
        _hover: {
          textDecoration: 'none'
        },
        _focus: {
          boxShadow: 'none'
        }
      },
      variants: {
        solid: {
          _hover: {
            color: '#fff'
          }
        }
      },
      defaultProps: {
        colorScheme: 'blue'
      }
    },
    Divider: {
      baseStyle: {
        borderColor: 'gray.300'
      }
    },
    Tabs: {
      baseStyle: {
        tab: {
          _focus: {
            boxShadow: 'none'
          }
        }
      }
    },
    Alert: {
      baseStyle: {
        container: {
          borderRadius: 'xl'
        }
      }
    }
  }
})

export default theme
