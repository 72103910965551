import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { instance } from 'utils/axios'

const initialState = {
  loading: false,
  seller: null,
  error: null
}

export const sellerDetailsAction = createAsyncThunk(
  'seller/sellerDetails',
  async (id, { getState, rejectWithValue }) => {
    try {
      const {
        sellerLogin: { sellerInfo }
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sellerInfo.token}`
        }
      }

      const { data } = await instance.get(`/api/sellers/${id}`, config)

      return data
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const sellerDetailsSlice = createSlice({
  name: 'sellerDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sellerDetailsAction.pending, (state) => {
        state.loading = true
      })
      .addCase(sellerDetailsAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.seller = payload
      })
      .addCase(sellerDetailsAction.rejected, (state, action) => {
        state.loading = false
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = action.error.message
        }
      })
  }
})

export default sellerDetailsSlice.reducer
