import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  locationFilter: localStorage.getItem('sellxpressLocationFilter')
    ? JSON.parse(localStorage.getItem('sellxpressLocationFilter'))
    : { value: 'All', label: 'All India' },
  error: null
}

export const setLocation = createAsyncThunk(
  'misc/setLocation',
  async (loc, thunkAPI) => {
    localStorage.setItem('sellxpressLocationFilter', JSON.stringify(loc))
    return loc
  }
)

export const setLocationFilterSlice = createSlice({
  name: 'setLocationFilter',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setLocation.pending, (state) => {
        state.loading = true
      })
      .addCase(setLocation.fulfilled, (state, { payload }) => {
        state.loading = false
        state.locationFilter = payload
      })
      .addCase(setLocation.rejected, (state, action) => {
        state.loading = false
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = action.error.message
        }
      })
  }
})

export default setLocationFilterSlice.reducer
