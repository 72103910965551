import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { instance } from 'utils/axios'

const initialState = {
  loading: false,
  categories: null,
  carBrands: null,
  bikeBrands: null,
  error: null
}

export const getChoices = createAsyncThunk(
  'misc/choices',
  async (args, { rejectWithValue }) => {
    try {
      const { data } = await instance.get('/api/choices')

      return data
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const choicesSlice = createSlice({
  name: 'choices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getChoices.pending, (state) => {
        state.loading = true
      })
      .addCase(getChoices.fulfilled, (state, { payload }) => {
        state.loading = false
        state.categories = payload.categories
        state.carBrands = payload.carBrands
        state.bikeBrands = payload.bikeBrands
      })
      .addCase(getChoices.rejected, (state, action) => {
        state.loading = false
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = action.error.message
        }
      })
  }
})

export default choicesSlice.reducer
