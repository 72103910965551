import React from 'react'
import { Spinner } from '@chakra-ui/react'

const Loader = ({ type }) => {
  if (type === 'small') {
    return (
      <Spinner
        size='sm'
        color='blue'
        style={{
          margin: 'auto',
          display: 'block'
        }}
      />
    )
  } else {
    return (
      <Spinner
        type='grow'
        color='blue'
        style={{
          width: '100px',
          height: '100px',
          margin: 'auto',
          display: 'block',
          marginTop: '3rem'
        }}
      />
    )
  }
}

export default Loader
