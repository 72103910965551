import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { instance } from 'utils/axios'
import { registeredSellerLogin } from './sellerAuth'

const initialState = {
  loading: false,
  sellerInfo: null,
  error: null
}

export const sellerRegisterAction = createAsyncThunk(
  'sellerAuth/sellerRegister',
  async ({ name, phone, email }, { rejectWithValue, dispatch }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      const { data } = await instance.post(
        '/api/sellers/register',
        { name, phone, email },
        config
      )

      dispatch(registeredSellerLogin(data))
      localStorage.setItem('sellxpressSellerInfo', JSON.stringify(data))

      return data
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const sellerRegisterSlice = createSlice({
  name: 'sellerRegister',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sellerRegisterAction.pending, (state) => {
        state.loading = true
      })
      .addCase(sellerRegisterAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.sellerInfo = payload
      })
      .addCase(sellerRegisterAction.rejected, (state, action) => {
        state.loading = false
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = action.error.message
        }
      })
  }
})

export default sellerRegisterSlice.reducer
