import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { instance } from 'utils/axios'

const initialState = {
  loading: false,
  ad: null,
  seller: null,
  error: null
}

export const adDetailsAction = createAsyncThunk(
  'ads/adDetails',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await instance.get(`/api/postings/${id}`)
      const { data: sD } = await instance.get(`/api/sellers/${data.sellerId}`)

      const { isAdmin, __v, updatedAt, ...sellerDetails } = sD

      return { data, sellerDetails }
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const adDetailsSlice = createSlice({
  name: 'adDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(adDetailsAction.pending, (state) => {
        state.loading = true
      })
      .addCase(adDetailsAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.ad = payload.data
        state.seller = payload.sellerDetails
      })
      .addCase(adDetailsAction.rejected, (state, action) => {
        state.loading = false
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = action.error.message
        }
      })
  }
})

export default adDetailsSlice.reducer
